import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listener para el estado de autenticación
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        user.getIdToken().then((idToken) => {
          localStorage.setItem("authToken", idToken); // Guarda el token por si lo necesitas en el frontend
        });
      } else {
        setCurrentUser(null);
        localStorage.removeItem("authToken");
      }
      setLoading(false); // Detiene el estado de carga una vez se detecta el usuario
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const signup = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Guardar el token al iniciar sesión
        return userCredential.user.getIdToken().then((idToken) => {
          localStorage.setItem("authToken", idToken);
          setCurrentUser(userCredential.user);
          return userCredential;
        });
      });
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    return auth.signOut().then(() => {
      setCurrentUser(null);
    });
  };

  const value = {
    currentUser,
    login,
    logout,
    signup
  };

  if (loading) {
    return <div></div>; // Mostrar un indicador de carga mientras se restaura la sesión
  }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};