import { useEffect } from "react";

function ANAMSedena () {

    useEffect(() => {
        window.location.href = "https://anam.grupoagcinfraestructura.com/";
    }, []);

  return (<></>)
}

export default ANAMSedena;