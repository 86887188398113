import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
 
const app = firebase.initializeApp({
  apiKey: "AIzaSyB-Y0g6vN5pIy8LDNmaGhWqgMhqE3IqKcw",
  authDomain: "garteel-816a5.firebaseapp.com",
  projectId: "garteel-816a5",
  storageBucket: "garteel-816a5.appspot.com",
  messagingSenderId: "118853305674",
  appId: "1:118853305674:web:d780c9b968d6dd4ed3adb3"
});
 
export const auth = firebase.auth();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
export default app;