import React from 'react';
import logo from '../img/logoBlanco.png';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() { 
        return ( 
            <div className='row m-0 bg-dark py-3 d-lg-none' style={{ height: '50px' }}>
                <div className="col-12 d-flex align-items-center h-100">
                    <button onClick={this.props.toggleSidebar} className='btnToggle btn btn-outline-light ms-2'><i className="bi bi-list"></i></button>
                    <img src={logo} alt="logoGarteel" className='ms-auto me-auto' style={{ position: 'relative', right: '5%', height: '40px', width: 'auto' }}/>
                </div>
            </div>
         );
    }
}
 
export default Navbar;