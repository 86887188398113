import Routes from './components/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
          <Routes/>
      </Router>
    </AuthProvider>
  );
}

export default App;
