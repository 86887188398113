import React, { Component } from 'react'

class Pagos extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() { 
        return (
            <div className="row m-0 mt-3 pb-5 d-flex justify-content-center animate__animated animate__fadeInUp">
                <div className="col-md-10 col-lg-10 col-xl-9 col-xxl-8 text-center">
                    <table className='table table-hover mb-0' style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>No.</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Monto</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Fecha</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Estatus</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Recibo</th>
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>1</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$800,000.00</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>02/09//2024</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.props.handleDescargarClick("/docs/proyectos/38-Recibo-1.pdf", "Recibo_1.pdf")}>Descargar</button></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>$300,000.00</td>
                                <td>20/10/2024</td>
                                <td>Pendiente</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>$300,000.00</td>
                                <td>20/11/2024</td>
                                <td>Pendiente</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>$300,000.00</td>
                                <td>20/12/2024</td>
                                <td>Pendiente</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>$300,000.00</td>
                                <td>20/01/2024</td>
                                <td>Pendiente</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>$183,906.01</td>
                                <td>02/03/2025 o entrega de obra</td>
                                <td>Pendiente</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>$2,183,906.01</td>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
 
export default Pagos;