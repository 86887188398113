import React from 'react';
import { auth } from '../../firebase';
import '../../css/sidebar.css';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';

class Cuenta extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            usuario: null,
            toggleSidebar: false,
            error: ''
        }
    }

    componentDidMount = () => {
        document.title = 'Mi Cuenta - Garteel Clientes';
        auth.onAuthStateChanged((user) => {
            if (user) {
            this.setState({
                usuario: user.email
            });
            }
        })
    }

    CerrarSesion = () => {
        auth.signOut();
        this.setState({
          usuario: null
        });
        window.location.href='/';
    }

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        })
    }

    cambiarContrasena = async () => {
        try {
            await auth.sendPasswordResetEmail(this.state.usuario, null);
            alert('Correo para restablecer contraseña enviado, si no lo encuenta, revise su bandeja de spam.');
        }catch (error) {
            switch (error.code) {
                case 'auth/internal-error':
                    this.setState({
                        error: 'Error interno'
                    })
                    break;
                default:
                    this.setState({
                        error: error.code
                    })
                    break;
            }
        }
    }

    render() { 
        return ( 
            <div className='app'>
                <Sidebar toggledSidebar={this.state.toggleSidebar} toggleSidebar={this.toggleSidebar}/>
                <main>
                    <div className="container-fluid p-0">
                        <Navbar toggleSidebar={this.toggleSidebar}/>
                        <div className='py-4 px-3'>
                            <h1>Mi Cuenta</h1>
                            <hr className='mt-1'/>
                            <div className="row px-3 d-flex justify-content-end mt-3">
                                <div className="col-lg-8 col-xl-7 col-xxl-6 px-3 py-2" style={{ border: '3px solid #2EBF70', borderRadius: '15px' }}>
                                    <h5>Cambiar Contraseña</h5>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <p className='m-0 p-0'>Se le enviará un link a su correo electrónico, en el cual podrá cambiar su contraseña.</p>
                                        </div>
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <button onClick={this.cambiarContrasena} className='btn btn-dark w-100 h-100' style={{ fontSize: '14px' }}>Recibir Correo</button>
                                        </div>
                                    </div>
                                    {this.state.error &&
                                        <p className='error mt-2 mb-0 w-100 border border-danger text-center py-2' style={{ borderRadius: '5px' }}>{this.state.error}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div> 
        );
    }
}
 
export default Cuenta;