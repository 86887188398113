import React, { Component } from 'react'

class Pagos extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() { 
        return (
            <div className="row m-0 mt-3 pb-5 d-flex justify-content-center animate__animated animate__fadeInUp">
                <div className="col-md-10 col-lg-10 col-xl-9 col-xxl-8 text-center">
                    <table className='table table-hover mb-0' style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>No.</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Monto</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Fecha</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Estatus</th>
                                <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Recibo</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
 
export default Pagos;